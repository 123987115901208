import Vue from 'vue'
import GAuth from 'vue-google-oauth2'
export default ({ app, store }) => {
  if (!store.state.user) {
    const gauthOption = {
      clientId: process.env.NUXT_ENV_GOOGLE_CLIENT_ID,
      scope: 'profile email',
      prompt: 'select_account'
    }
    Vue.use(GAuth, gauthOption)
  }
}
