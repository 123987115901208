import Vue from 'vue'
import VueSocketIO from 'vue-socket.io'
import SocketIO from 'socket.io-client'
import { getToken } from '~/common/utils/auth'

const options = {
  withCredentials: false,
  autoConnect: !!getToken(),
  query: {
    token: getToken()
  }
}
const ioParams = { 'reconnection limit': 3000, 'max reconnection attempts': Number.MAX_VALUE, 'connect timeout': 3000 }
export default ({ store }) => {
  Vue.use(new VueSocketIO({
    // debug: true,
    connection: SocketIO(process.env.NUXT_ENV_SOCKET_URL, options, ioParams),
    vuex: {
      store,
      mutationPrefix: 'SOCKET_'
    }
  }))
}
