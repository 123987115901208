export const state = () => ({
  data: null,
  subjects: [],
  classes: [],
  playlistDoc: [],
  playlistExam: [],
  myPlaylistDoc: [],
  myPlaylistExam: []
})
export const getters = {
  data: state => state.data,
  subjects: state => state.subjects,
  classes: state => state.classes,
  playlistDoc: state => state.playlistDoc,
  playlistExam: state => state.playlistExam,
  myPlaylistDoc: state => state.myPlaylistDoc,
  myPlaylistExam: state => state.myPlaylistExam
}
export const mutations = {
  SET_DATA: (state, data) => {
    state.data = data
  },
  SET_SUBJECT: (state, data) => {
    state.subjects = data
  },
  SET_CLASS: (state, data) => {
    state.classes = data
  },
  SET_PLAYLIST_DOC: (state, data) => {
    state.playlistDoc = data
  },
  SET_PLAYLIST_EXAM: (state, data) => {
    state.playlistExam = data
  },
  SET_MY_PLAYLIST_DOC: (state, data) => {
    state.myPlaylistDoc = data
  },
  SET_NUMBER_MY_PLAYLIST_DOC: (state, { ind, number }) => {
    if (number === 0) {
      state.myPlaylistDoc.splice(ind, 1)
    } else {
      state.myPlaylistDoc[ind].number_file = number
    }
  },
  SET_NAME_MY_PLAYLIST_DOC: (state, { ind, name }) => {
    state.myPlaylistDoc[ind].name = name
  },
  SET_NUMBER_MY_PLAYLIST_EXAM: (state, { ind, number }) => {
    if (number === 0) {
      state.myPlaylistExam.splice(ind, 1)
    } else {
      state.myPlaylistExam[ind].number_exam = number
    }
  },
  SET_NAME_MY_PLAYLIST_EXAM: (state, { ind, name }) => {
    state.myPlaylistExam[ind].name = name
  },
  SET_MY_PLAYLIST_EXAM: (state, data) => {
    state.myPlaylistExam = data
  }
}
export const actions = {
  setData ({ commit }, data) {
    commit('SET_DATA', data)
  },
  setSubject ({ commit }, data) {
    commit('SET_SUBJECT', data)
  },
  setClass ({ commit }, data) {
    commit('SET_CLASS', data)
  },
  setPlaylistDoc ({ commit }, data) {
    commit('SET_PLAYLIST_DOC', data)
  },
  setPlaylistExam ({ commit }, data) {
    commit('SET_PLAYLIST_EXAM', data)
  },
  setNumberMyPlaylistDoc ({ commit }, data) {
    commit('SET_NUMBER_MY_PLAYLIST_DOC', data)
  },
  setNameMyPlaylistDoc ({ commit }, data) {
    commit('SET_NAME_MY_PLAYLIST_DOC', data)
  },
  setMyPlaylistDoc ({ commit }, data) {
    commit('SET_MY_PLAYLIST_DOC', data)
  },
  setNumberMyPlaylistExam ({ commit }, data) {
    commit('SET_NUMBER_MY_PLAYLIST_EXAM', data)
  },
  setNameMyPlaylistExam ({ commit }, data) {
    commit('SET_NAME_MY_PLAYLIST_EXAM', data)
  },
  setMyPlaylistExam ({ commit }, data) {
    commit('SET_MY_PLAYLIST_EXAM', data)
  }
}
